const colorOptions = [
    { value: "None", image: "", label: "None" },
    { value: "FFFFFF", image: "/images/utils/Images/CUPSIMG_SVG/WHITE 101.svg", label: "White" },
    { value: "FFFF00", image: "/images/utils/Images/CUPSIMG_SVG/YELLOW 102.svg", label: "Yellow" },
    { value: "1a8157", image: "/images/utils/Images/CUPSIMG_SVG/KELLY 105.svg", label: "Kelly Green" },
    { value: "0705A6", image: "/images/utils/Images/CUPSIMG_SVG/ROYAL BLUE 113.svg", label: "Royal Blue" },
    { value: "000000", image: "/images/utils/Images/CUPSIMG_SVG/BLACK 115.svg", label: "Black" },
    { value: "8B0000", image: "/images/utils/Images/CUPSIMG_SVG/DARK RED 119.svg", label: "Dark Red" },
    { value: "FF5D8F", image: "/images/utils/Images/CUPSIMG_SVG/PUNCH.svg", label: "Punch" },
    { value: "5d38ae", image: "/images/utils/Images/CUPSIMG_SVG/PURPLE 103.svg", label: "Purple" },
    { value: "808080", image: "/images/utils/Images/CUPSIMG_SVG/GRAY 104.svg", label: "Grey" },
    { value: "D64B0F", image: "/images/utils/Images/CUPSIMG_SVG/ORANGE 106.svg", label: "Orange" },
    { value: "006400", image: "/images/utils/Images/CUPSIMG_SVG/DARK GREEN 107.svg", label: "Dark Green" },
    { value: "55252C", image: "/images/utils/Images/CUPSIMG_SVG/MAROON 108.svg", label: "Maroon" },
    { value: "5E3C30", image: "/images/utils/Images/CUPSIMG_SVG/BROWN 110.svg", label: "Brown" },
    { value: "BF181D", image: "/images/utils/Images/CUPSIMG_SVG/RED 111.svg", label: "Red" },
    { value: "2C65D9", image: "/images/utils/Images/CUPSIMG_SVG/LIGHT BLUE 112.svg", label: "Light Blue" },
    { value: "282641", image: "/images/utils/Images/CUPSIMG_SVG/NAVY BLUE 114.svg", label: "Navy Blue" },
    { value: "D79706", image: "/images/utils/Images/CUPSIMG_SVG/YELLOW GOLD 116.svg", label: "Yellow Gold" },
    { value: "9F8310", image: "/images/utils/Images/CUPSIMG_SVG/VEGAS GOLD 117.svg", label: "Vegas Gold" },
    { value: "008080", image: "/images/utils/Images/CUPSIMG_SVG/TEAL 118.svg", label: "Teal" },
    { value: "D1AB3E", image: "/images/utils/Images/CUPSIMG_SVG/HARVEST GOLD 120.svg", label: "Harvest Gold" },
    { value: "8A8885", image: "/images/utils/Images/CUPSIMG_SVG/SILVER 121.svg", label: "Silver" },
    { value: "9A8154", image: "/images/utils/Images/CUPSIMG_SVG/CARDBOARD 122.svg", label: "Cardboard" },
];

export default colorOptions;
