export function mapCharacter(ch) {
    let chname = ch.toUpperCase();
    if (ch.toUpperCase() === "!") { chname = "EXCLAMATION"; }
    if (ch === '"') { chname = "QUOTES"; }
    if (ch.toUpperCase() === "#") { chname = "POUND"; }
    if (ch.toUpperCase() === "$") { chname = "DOLLAR"; }
    if (ch.toUpperCase() === "%") { chname = "PERCENT"; }
    if (ch.toUpperCase() === "&") { chname = "AMPERSAND"; }
    if (ch.toUpperCase() === "'") { chname = "APOSTRAPHE"; }
    if (ch.toUpperCase() === "*") { chname = "ASTERISK"; }
    if (ch.toUpperCase() === "+") { chname = "PLUS"; }
    if (ch.toUpperCase() === ",") { chname = "COMMA"; }
    if (ch.toUpperCase() === "-") { chname = "MINUS"; }
    if (ch.toUpperCase() === ".") { chname = "PERIOD"; }
    if (ch.toUpperCase() === "/") { chname = "SLASH"; }
    if (ch.toUpperCase() === ":") { chname = "COLON"; }
    if (ch.toUpperCase() === ";") { chname = "SEMICOLON"; }
    if (ch.toUpperCase() === "=") { chname = "EQUALS"; }
    if (ch.toUpperCase() === "?") { chname = "QUESTION"; }
    if (ch.toUpperCase() === "@") { chname = "AT"; }
    return chname;

}
